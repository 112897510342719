import { AppFC } from '@types';
import { LinkExternal } from '@managers/links';
import { FooterExtraLinksContentStyled, FooterExtraLinksStyled } from './styled';
import { FooterExtraLinksProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const FooterExtraLinks: AppFC<FooterExtraLinksProps> = props => {
  const {
    termsForEuResidentsLinkProps,
    rightOfWithdrawalLinkProps,
    notificationOfIllegalityLinkProps
  } = props;
  return _jsxs(FooterExtraLinksStyled, {
    children: [_jsx(LinkExternal, {
      href: termsForEuResidentsLinkProps.href,
      children: _jsx(FooterExtraLinksContentStyled, {
        children: termsForEuResidentsLinkProps.children
      })
    }), _jsx(LinkExternal, {
      href: rightOfWithdrawalLinkProps.href,
      children: _jsx(FooterExtraLinksContentStyled, {
        children: rightOfWithdrawalLinkProps.children
      })
    }), _jsx(LinkExternal, {
      href: notificationOfIllegalityLinkProps.href,
      children: _jsx(FooterExtraLinksContentStyled, {
        children: notificationOfIllegalityLinkProps.children
      })
    })]
  });
};